import React from 'react';
import Layout from "../components/Layout";
import {defineMessages, useIntl} from "react-intl";
import greetingPeople from "../assets/images/customers/greeting-people.svg";
import employeesBoard from "../assets/images/customers/employees-board.svg";
import ticket from "../assets/images/customers/ticket.svg";
import collaboration from "../assets/images/customers/collaboration.svg";
import contract from "../assets/images/customers/contract.svg";
import userBoard from "../assets/images/customers/user-board.svg";
import buttons from "../assets/images/customers/buttons.svg";
import userDocuments from "../assets/images/customers/user-documents.svg";
import middleLine from "../assets/images/line-bottom-middle.svg";
import employeeReport from "../assets/images/customers/employee-report.svg";
import junior from "../assets/images/customers/junior.svg";
import tools from "../assets/images/customers/tools.svg";
import customersBoard from "../assets/images/customers/customers-board.svg";
import juniorRates from "../assets/images/customers/junior-rates.svg";
import rateBoard from "../assets/images/customers/rate-board.svg";
import partnerShip from "../assets/images/customers/partnership.svg";
import juniorSenior from "../assets/images/customers/junior-senior.svg";
import longArrow from "../assets/images/customers/long-arrow.svg";
import shortArrow from "../assets/images/customers/short-arrow.svg";
import arrow from "../assets/images/customers/arrow.svg";
import zigzagLine from "../assets/images/scrumboard/zigzag-line.svg";
import line1 from "../assets/images/customers/line1.svg";
import line2 from "../assets/images/customers/line2.svg";
import line3 from "../assets/images/customers/line3.svg";
import line4 from "../assets/images/customers/line4.svg";
import line5 from "../assets/images/customers/line5.svg";
import line6 from "../assets/images/customers/line6.svg";
import line7 from "../assets/images/customers/line7.svg";
import Img from "../components/Img";

const intro = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Customers & Employees'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'CRM available'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship will boost your business with customer relationship management. HR managers have instant access to all employees’ administrative documents.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Everything in one place'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'All of your employee files and reports are in one Scrumship app for better team management. Store all your contracts securely and instantly identify key dates and obligations.'
    },
});

const employeesManagement = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Employees Management'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'One-click access'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Easily manage your employees\' contact information. Gain an easy one-click access to everyone’s contact details, making communication and organisation instant.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Users and Roles Management'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship has an effective and easy-to-use employee management platform that allows you grant employees the appropriate level of access.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'employees board in Scrumship employee management app'
    },
});

const userProfile = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'User Profile'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Employee in details'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'User profile allows you to view individual employee records in detail.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'General'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'General information like contact details, position, projects in which employee works on.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Employment details'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'Employment details where you keep records of hourly net and gross rates, type of employment, availability, employment agreements, non-competes and other user related documents.'
    },
    advice3Header: {
        id: 'Header.Text',
        defaultMessage: 'Job details'
    },
    advice3Text: {
        id: 'Content.Text',
        defaultMessage: 'Job details where you maintain records such as position, availability, education, work experience, skills and employee description.'
    },
    advice4Header: {
        id: 'Header.Text',
        defaultMessage: 'Just one Click'
    },
    advice4Text: {
        id: 'Content.Text',
        defaultMessage: 'Generate employee Job contact or User contract with non-disclosure agreement with one click. If you send your employees for collaboration in few seconds generate employee CV and download it as PDF or email.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'user board in Scrumship app to manage employees'
    },
});

const employeesReport = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Employee Evaluation Report'
    },
    headerH2: {
        id: 'Header.Text',
        defaultMessage: 'Scrumship user report gives you a visual preview of your employee:'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Efficacy review'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Periodic performance evaluation that used to take your managers all day, can now be managed faster.\n ' +
            'A combination of activity, scores and availability metrics and simple graphics provides an easy, highly effective tool for identifying and correcting employee\'s issues.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Activity score'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'Activity Score; compares activity on the code vs. logged time.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Availability score'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'Availability score; compares logged time vs. declared working times.'
    },
    advice3Header: {
        id: 'Header.Text',
        defaultMessage: 'Estimated score'
    },
    advice3Text: {
        id: 'Content.Text',
        defaultMessage: 'Estimated Score; compares time estimated vs. time spent'
    },
    advice4Header: {
        id: 'Header.Text',
        defaultMessage: 'Kudos and improvements'
    },
    advice4Text: {
        id: 'Content.Text',
        defaultMessage: 'Take notes throughout the year and easily highlight areas of improvement, compare strengths and weaknesses, recommend actionable goals and provide constructive feedback.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'user report board in Scrumship employee management app'
    },
});

const customersCollaboration = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Customers & Collaboration'
    },
    subtitle: {
        id: 'Subtitle.Text',
        defaultMessage: 'Get your whole company to the next level with the next generation of project and development management application.'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Efficacy review'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship Customers page allows you to easily manage projects of your Customers and contracts for employees in collaboration with Customers.\n' + 'Scrumship distinguishes between two types of customers accounts:'
    },
    protip1List1: {
        id: 'Content.Text',
        defaultMessage: 'Internal Customers, who do not have their own account. You are permitted to set and manage the whole project flow for them.'
    },
    protip1List2: {
        id: 'Content.Text',
        defaultMessage: 'Collaboration Customers, with their own Scrumship account. Customers can view and update their own data, manage projects, create and receive invoices.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'One-click access'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Easily manage your employees\' contact information. \n' + 'Gain an easy one-click access to everyone’s contact details, making communication and organisation instant.'
    },
    protip3Header: {
        id: 'Header.Text',
        defaultMessage: 'Manage employees rates'
    },
    protip3Text: {
        id: 'Content.Text',
        defaultMessage: 'When adding an employee to collaboration for a certain Customer you can set rates and Generate User Collaboration Contract with one click. From one place you can manage your employees rates, edit and send emails with contracts.'
    },
    protip4Header: {
        id: 'Header.Text',
        defaultMessage: 'Awareness all parties'
    },
    protip4Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship Rate Bound feature allows formally documenting the commercial project.\n' + 'It helps to make sure that all parties know what rates for employees are applied and gain the right collaboration to achieve mutual goals and business growth without wasting time for periodic rate negotiations.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Employees in collaboration'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship allows you to make your employees available for collaboration. The ability to generate contracts significantly speeds up work.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Rate board'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'Rate bounds schedule is flexible as you can add new positions and bounds. If you have different rates specified per project, Scrumship allows also to override your global rates per customer.'
    },
    endText: {
        id: 'Content.Text',
        defaultMessage: 'With this feature you can easily draft or negotiate a collaboration agreement not only for a set period of time, but also set Rate bounds counted in working hours after which your rate will be automatically increased.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'employees board in Scrumship'
    },
    board2: {
        id: 'Alt.Text',
        defaultMessage: 'employees rates board in Scrumship'
    },
});

const globalAlts =defineMessages({
    junior: {
        id: 'Alt.Text',
        defaultMessage: 'junior developer'
    },
    notes: {
        id: 'Alt.Text',
        defaultMessage: 'flying notes'
    },
    developer: {
        id: 'Alt.Text',
        defaultMessage: 'senior developer'
    },
    card: {
        id: 'Alt.Text',
        defaultMessage: 'to do card'
    },
    people: {
        id: 'Alt.Text',
        defaultMessage: 'cooperating people'
    },
    contract: {
        id: 'Alt.Text',
        defaultMessage: 'signed contract'
    },
    cv: {
        id: 'Alt.Text',
        defaultMessage: 'cv'
    },
    buttons: {
        id: 'Alt.Text',
        defaultMessage: 'green and violet button'
    }
});

const CustomersEmployees = ({location}) => {
    const {formatMessage} = useIntl();
    return (
        <Layout pageName='customers-employees-subpage' location={location}>
            <section className='intro'>
                <div className='container'>
                    <div className='section-one'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(intro.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container  text-left'>
                                <div className='title title-no-line'><h3>{formatMessage(intro.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(intro.protip1Text)}</p>
                            </div>
                            <div className='arrow'>
                                <Img src={longArrow} alt=""/>
                            </div>
                        </div>
                        <div className='people'>
                            <div className='image-container full-width'>
                                <Img src={greetingPeople} alt={formatMessage(globalAlts.people)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(intro.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(intro.protip2Text)}</p>
                            </div>
                            <div className='image-container full-width mt-xs'>
                                <Img src={zigzagLine} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='employees-management'>
                <div className='container'>
                    <div className='section-two'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(employeesManagement.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title title-no-line'>
                                    <h3>{formatMessage(employeesManagement.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(employeesManagement.protip1Text)}</p>
                            </div>
                            <div className='arrow'>
                                <Img src={shortArrow} alt=""/>
                            </div>
                        </div>
                        <div className='board'>
                            <div className='image-container full-width'>
                                <Img src={employeesBoard} alt={formatMessage(employeesManagement.board)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(employeesManagement.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(employeesManagement.protip2Text)}</p>
                            </div>
                        </div>
                        <div className='ticket'>
                            <div className='image-container full-width'>
                                <Img src={ticket} alt={formatMessage(globalAlts.card)}/>
                            </div>
                        </div>
                        <div className='people'>
                            <div className='image-container full-width'>
                                <Img src={collaboration} alt={formatMessage(globalAlts.junior)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='user-profile'>
                <div className='container'>
                    <div className='section-three'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(userProfile.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(userProfile.protip1Header)}</h3>
                                </div>
                                <p className='protip-black'>{formatMessage(userProfile.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='contract'>
                            <div className='image-container full-width'>
                                <Img src={contract} alt={formatMessage(globalAlts.contract)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper1'>
                            <div className='advice-container advice1'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(userProfile.advice1Header)}</h4>
                                    <p>{formatMessage(userProfile.advice1Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1a'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-advice1b'>
                                <div className='image-container full-width'>
                                    <Img src={line1} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='board'>
                            <div className='image-container full-width'>
                                <Img src={userBoard} alt={formatMessage(userProfile.board)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper2'>
                            <div className='advice-container advice2'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(userProfile.advice2Header)}</h4>
                                    <p>{formatMessage(userProfile.advice2Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice'>
                                <div className='image-container full-width'>
                                    <Img src={line2} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='advice-wrapper3'>
                            <div className='advice-container advice3'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(userProfile.advice3Header)}</h4>
                                    <p>{formatMessage(userProfile.advice3Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice3a'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-advice3b'>
                                <div className='image-container full-width'>
                                    <Img src={line3} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='advice-wrapper4'>
                            <div className='buttons'>
                                <div className='image-container full-width'>
                                    <Img src={buttons} alt=""/>
                                </div>
                            </div>
                            <div className='advice-container advice4'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(userProfile.advice4Header)}</h4>
                                    <p>{formatMessage(userProfile.advice4Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice4a'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-advice4b'>
                                <div className='image-container full-width'>
                                    <Img src={line4} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='documents'>
                            <div className='image-container full-width'>
                                <Img src={userDocuments} alt={formatMessage(globalAlts.cv)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='employees-report'>
                <div className='container'>
                    <div className='section-fourth'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(employeesReport.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(employeesReport.protip1Header)}</h3>
                                </div>
                                <p className='protip-black'>{formatMessage(employeesReport.protip1Text)}</p>
                            </div>
                            <div className='image-container full-width mt-xs'>
                                <Img src={zigzagLine} alt=""/>
                            </div>
                        </div>
                        <h2 className='header'>{formatMessage(employeesReport.headerH2)}</h2>
                        <div className='advice-group'>
                            <div className='advice-wrapper1'>
                                <div className='advice-container advice1'>
                                    <div className='advice-body'>
                                        <h4>{formatMessage(employeesReport.advice1Header)}</h4>
                                        <p>{formatMessage(employeesReport.advice1Text)}</p>
                                    </div>
                                </div>
                                <div className='line-advice'>
                                    <div className='image-container full-width'>
                                        <Img src={line5} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className='advice-wrapper2'>
                                <div className='advice-container advice2'>
                                    <div className='advice-body'>
                                        <h4>{formatMessage(employeesReport.advice2Header)}</h4>
                                        <p>{formatMessage(employeesReport.advice2Text)}</p>
                                    </div>
                                </div>
                                <div className='line-advice'>
                                    <div className='image-container full-width'>
                                        <Img src={middleLine} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className='advice-wrapper3'>
                                <div className='advice-container advice3'>
                                    <div className='advice-body'>
                                        <h4>{formatMessage(employeesReport.advice3Header)}</h4>
                                        <p>{formatMessage(employeesReport.advice3Text)}</p>
                                    </div>
                                </div>
                                <div className='line-advice'>
                                    <div className='image-container full-width'>
                                        <Img src={line5} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='board'>
                            <div className='image-container full-width'>
                                <Img src={employeeReport} alt={formatMessage(employeesReport.board)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper4'>
                            <div className='advice-container advice4'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(employeesReport.advice4Header)}</h4>
                                    <p>{formatMessage(employeesReport.advice4Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='junior'>
                            <div className='image-container full-width'>
                                <Img src={junior} alt={formatMessage(globalAlts.junior)}/>
                            </div>
                        </div>
                        <div className='tools'>
                            <div className='image-container full-width'>
                                <Img src={tools} alt={formatMessage(globalAlts.notes)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='customers-collaboration'>
                <div className='container'>
                    <div className='section-fifth'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(customersCollaboration.header)}</h1>
                        </div>
                        <p className='subtitle'>{formatMessage(customersCollaboration.subtitle)}</p>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(customersCollaboration.protip1Header)}</h3>
                                </div>
                                <p className='protip-black'>{formatMessage(customersCollaboration.protip1Text)}</p>
                                <ul className='list'>
                                    <li><span className='protip-black'>{formatMessage(customersCollaboration.protip1List1)}</span></li>
                                    <li><span className='protip-black'>{formatMessage(customersCollaboration.protip1List2)}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className='advice-wrapper1'>
                            <div className='advice-container advice1'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(customersCollaboration.advice1Header)}</h4>
                                    <p>{formatMessage(customersCollaboration.advice1Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-advice2'>
                                <div className='image-container full-width'>
                                    <Img src={line6} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='board'>
                            <div className='image-container full-width'>
                                <Img src={customersBoard} alt={formatMessage(customersCollaboration.board)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(customersCollaboration.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(customersCollaboration.protip2Text)}</p>
                            </div>
                        </div>
                        <div className='junior'>
                            <div className='image-container full-width'>
                                <Img src={juniorRates} alt={formatMessage(globalAlts.junior)}/>
                            </div>
                        </div>
                        <div className='protip3'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(customersCollaboration.protip3Header)}</h3>
                                </div>
                                <p className='protip-black'>{formatMessage(customersCollaboration.protip3Text)}</p>
                            </div>
                            <div className='arrow'>
                                <Img src={arrow} alt=""/>
                            </div>
                        </div>
                        <div className='board2'>
                            <div className='image-container full-width'>
                                <Img src={rateBoard} alt={formatMessage(customersCollaboration.board)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper2'>
                            <div className='advice-container advice2'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(customersCollaboration.advice2Header)}</h4>
                                    <p>{formatMessage(customersCollaboration.advice2Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-advice2'>
                                <div className='image-container full-width'>
                                    <Img src={line7} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='protip4'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(customersCollaboration.protip4Header)}</h3>
                                </div>
                                <p className='protip-black'>{formatMessage(customersCollaboration.protip4Text)}</p>
                            </div>
                            <div className='image-container full-width mt-xs'>
                                <Img src={zigzagLine} alt=""/>
                            </div>
                        </div>
                        <div className="people">
                            <div className='image-container full-width'>
                                <Img src={partnerShip} alt={formatMessage(globalAlts.people)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='summary'>
                <div className='container'>
                    <div className='section-sixth'>
                        <div className='people'>
                            <div className='image-container full-width'>
                                <Img src={juniorSenior} alt={formatMessage(globalAlts.developer)}/>
                            </div>
                        </div>
                        <p className='protip-black'>{formatMessage(customersCollaboration.endText)}</p>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default CustomersEmployees;
